import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions} from 'react-native';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { HelperText, Button, Card, Title, Paragraph, List, Portal, Dialog, DataTable, RadioButton, Checkbox, Drawer, TextInput } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { emailValidator} from '../utils/index';


const theme = {
    ...DefaultTheme,
    roundness: 2,
    myOwnProperty: true,
    colors: {
      ...DefaultTheme.colors,
      background: '#292929',
      primary: 'white',
      accent: 'white',
      text: '#ffffff',
      surface:'#292929'
    },
  };

function ForgotPasswordScreen({ navigation, route }) {

    const [email,setEmail] = useState({ value: '', error: '' })
    const [ok, setOk] = useState(false)

    const [firstOpen,setFirstOpen] = useState()

    const getData = async () => {
        try {
          const value = await AsyncStorage.getItem('@id_usertable')
          return value
        }
        catch(e) {
          // error reading value
        }
      }

    const onSendPressed = async () => {
        const emailError = emailValidator(email.value);
    
        if (emailError) {
            var tempEmail = email
            email.error =emailError
            setEmail({...email, tempEmail})
            return
        }
        else{
            await fetch('https://monicatool.cloud/api/auth/forgotPW', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin':'*'

                },
                body: JSON.stringify({
                    email: email.value
                })
            })
            .then((response) => response.json())
            .then((resdata) => {
                console.log(resdata)
                if(resdata.status === 'OK')
                    setOk(true)
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }

    useEffect(()=>{

      }, [firstOpen])

    return (
        <PaperProvider theme={theme}>
            <Portal>
                <Dialog visible={true} style={{width:'50%', alignSelf:'center'}}>
                    <Dialog.Title>
                        <View style={{flexDirection:'column', width:'100%', alignItems:'center'}}>
                            <View>
                                <Text style={{fontSize:20}}>MONICA tool</Text>
                            </View>
                            <View>
                                <Text style={{fontSize:14}}>your ally in monitoring system design</Text>
                            </View>
                        </View>
                    </Dialog.Title>
                    <Dialog.Content>
                        <View style={{flexDirection:'column'}}>
                            <View style={styles.rowDialog}>
                                <Text style={styles.textTitle}>Reset your password</Text>
                                <Text style={styles.textSubTitle}>Enter the email associated with your account and we'll send and email with instructions to reset your password</Text>
                            </View>
                            <View style={styles.rowDialog}>
                                <TextInput
                                    label="Email"
                                    returnKeyType="next"
                                    value={email.value}
                                    onChangeText={text => setEmail({ value: text, error: '' })}
                                    error={!!email.error}
                                    autoCapitalize="none"
                                    autoCompleteType="email"
                                    textContentType="emailAddress"
                                    keyboardType="email-address"
                                    underlineColor="grey"
                                    theme={{ colors: { placeholder: 'grey' } }}
                                />
                                <HelperText type="error" visible={!!email.error}>
                                    {email.error}
                                </HelperText>
                            </View>
                            <View style={styles.rowDialog}>
                                {ok ? (
                                    <Button mode="contained" 
                                        color="white"
                                        onPress={()=>{navigation.navigate('SignIn')}}
                                    >
                                        Email sent, go to SignIn page
                                    </Button>
                                ) : (
                                    <Button mode="contained" 
                                        color="white"
                                        onPress={onSendPressed}
                                    >
                                        Send Instructions
                                    </Button>
                                )}
                            </View>
                        </View>
                    </Dialog.Content>
                </Dialog>
            </Portal>
            <View style={{flex:1, with: useWindowDimensions().width, height:useWindowDimensions().height, backgroundColor: theme.colors.background}}>
            
            </View>
        </PaperProvider>
    );
}

export default ForgotPasswordScreen

const styles = StyleSheet.create({
    title: {
      fontSize: 16,
      textTransform: 'uppercase',
    },
    textTitle: {
        color:"white",
        fontSize: 20,
        //textTransform: 'uppercase',
    },
    textSubTitle:{
        color:"white",
        fontSize: 14,
    },
    boxParameters :{
        padding: 2
    },
    rowDialog:{
        flex:1,
        paddingVertical:20
    },
    forgot:{
        textAlign: 'right',
        color:'grey',
        textDecorationLine: 'underline'
    },
    signup:{
        textAlign: 'center',
        color:'grey',
        textDecorationLine: 'underline'
    },

});