import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, Alert} from 'react-native';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { Avatar, Button, Card, Title, Paragraph, List, Portal, Dialog, DataTable, RadioButton, Checkbox, Drawer, TextInput, ActivityIndicator } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Icon from 'react-native-vector-icons/MaterialIcons';

import Markdown from 'react-native-easy-markdown';
import Clipboard from 'expo-clipboard';

function Parametri(props) {

    const [text, setText] = React.useState([]);
    const [firstOpen,setFirstOpen]= useState()

    useEffect(()=>{
        props.options.map((o,i)=>{
            setText(old => [...old, o.value])
        })
      }, [firstOpen])

    const updateCheck = (value,index)=>{
        var temp = props.options
        temp[index].sel=value
        props.setOptions(old => [...temp])
    }
    
    const updateRadio = (index) => {
        var temp = props.options
        temp.map((o,i) =>{
            if (i === index)
                temp[i].sel = true
            else
                temp[i].sel = false
        })
        props.setOptions(old => [...temp])
    }

    const updateNSingle = (txt, index) => {
        var arr = text
        arr[index]=txt
        setText(old => [...arr])
    
        var temp = props.options
        temp[index].value = txt
        props.setOptions(old => [...temp])
    }

    const updateDB = async(idModOpt,options) => {
        //props.load(true)
        await fetch('https://monicatool.cloud/api/update', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idTab: props.idTab,
                idModOpt: idModOpt,
                options: options
            })
        })
        .then((response) => response.json())
        .then((resdata) => {
            //props.load(false)
            props.close()
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const clearDB = async (idModOpt, options) =>{
        var temp = []
        options.map((o,i)=>{
            o.sel = false
            o.value = null
            temp.push(o)
        })
        await fetch('https://monicatool.cloud/api/update', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idTab: props.idTab,
                idModOpt: idModOpt,
                options: temp
            })
        })
        .then((response) => response.json())
        .then((resdata) => {
            //props.load(false)
            props.close()
        })
        .catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
        <Dialog.Title>{props.title}</Dialog.Title>
        <Dialog.ScrollArea>
        <ScrollView>
        <Dialog.Content>
            <DataTable>
            {props.options.map((o,i) =>{
                return(
                    <>
                    <DataTable.Row>
                        <DataTable.Cell style={{flex:7}}>{o.name}</DataTable.Cell>
                        <DataTable.Cell style={{flex:1}}>
                          {props.type ==='single' ? (
                          // Radio button scelta singola
                            <RadioButton
                              value={i}
                              status={ o.sel ? 'checked' : 'unchecked' }
                              onPress={() =>{
                                updateRadio(i)
                                }
                              }
                             />
                           ) :
                          ( props.type ==='multiple' ? (
                            <Checkbox
                              status={o.sel ? 'checked' : 'unchecked'}
                              onPress={() => {
                                updateCheck(!o.sel,i)
                              }}
                            />
                          ):
                          (props.type ==='nsingle' ? (
                            <TextInput
                              value={text[i]}
                              underlineColor="white"
                              style={{height:40}}
                              theme={{ colors: { placeholder: 'white',background: '#545454' } }}
                              onChangeText={
                                //text => setText(text)
                                text => updateNSingle(text,i)
                             }
                            />
                          ) : (
                            <TextInput
                              value={text[i]}
                              underlineColor="white"
                              style={{height:40}}
                              theme={{ colors: { placeholder: 'white',background: '#545454' } }}
                              onChangeText={
                                //text => setText(text)
                                text => updateNSingle(text,i)
                             }
                            />
                          )))}</DataTable.Cell>
                    </DataTable.Row>
                    </>
                )
              })}  
            </DataTable>
        </Dialog.Content>
        </ScrollView>
        </Dialog.ScrollArea>
        <Dialog.Actions>
            <View style={{flex:4}}></View>
            <View style={{flex:1}}>
            <Button mode="outlined" 
                style={{borderColor: 'white'}}
                onPress={() => {
                clearDB(props.idPar,props.options)
            }}>
            Clear
            </Button>
            </View>
            <View style={{flex:0.5}}>
            </View>
            <View style={{flex:1}}>
            <Button mode="outlined" 
                style={{borderColor: 'white'}}
                onPress={() => {
                updateDB(props.idPar,props.options)
            }}>
            Save
            </Button>
            </View>
        </Dialog.Actions>
        </>
    )
  }



const theme = {
    ...DefaultTheme,
    roundness: 2,
    myOwnProperty: true,
    colors: {
      ...DefaultTheme.colors,
      background: '#292929',
      primary: 'white',
      accent: 'white',
      text: '#ffffff',
      surface:'#292929'
    },
  };

function HomeScreen() {

    const [visible, setVisible] = useState(false)
    const [des,setDes] = useState([])
    const [draw,setDraw] = useState([])
    const [ben,setBen] = useState([])
    const [impl,setImpl] = useState([])
    const [ext,setExt] = useState([])
    const [isLoading, setisLoading] = useState(true)
    const [parametro, setParametro] = useState('')
    const [options,setOptions]=useState([])
    const [idPar, setIdPar]=useState()
    const [idUserTable, setIdUserTable]=useState()
    const [firstOpen,setFirstOpen] = useState()
    const [list, setList] = useState([])
    const [type,setType] = useState()
    const [diagInstances, setDiagInstances] = useState(false)
    const [diagNoData, setDiagNoData] = useState(false)
    const [instances, setInstances] = useState([])
    const [loading, setLoading] = useState(false);
    const [cleanInput, setCleanInput] = useState(false)
    const [heightYourInput, setHeightYourInput] = useState(0)
    const [clipboardMsg, setClipBoardMsg] = useState('Click on reference to copy to clipbard')
    const [description,setDescription] = useState(false)
    const [mdData, setMdData] = useState('')
    const [name, setName] = useState('')
    const [token, setToken] = useState()
    const [end,setEnd] = useState(0)

    //const showDialog = () => setVisible(true)
    const hideDialog = () => {
        setVisible(false)
        fetchTree(idUserTable)
    }

    const hideDialogInstance = () =>{
        setDiagInstances(false)
    }

    const hideDialogNoData = () =>{
        setDiagNoData(false)
    }
    const hideDialogClean = ()  =>{
        setCleanInput(false)
    }
    const hideDialogDescription = () =>{
        setDescription(false)
    }
    const getData = async () => {
        try {
          const value = await AsyncStorage.getItem('@id_usertable')
          return value
        }
        catch(e) {
          // error reading value
        }
      }

    const getValue = async (idPar) =>{
        await fetch('https://monicatool.cloud/api/parameter', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: idUserTable,
                idPar: idPar
            })
        })
        .then((response) => response.json())
        .then((resdata) => {
            setParametro(resdata.name)
            setType(resdata.type)
            setOptions(old => [...resdata.options])
            setIdPar(idPar)
            setVisible(true)


        })
        .catch((error) => {
            console.log(error)
        })
    }


    const fetchTree = async (id) =>{
        await fetch('https://monicatool.cloud/api/tree/'+id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response) => response.json())
        .then((resdata) => {
            setList(old => [...resdata])
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const fetchName = async (id) =>{
        await fetch('https://monicatool.cloud/api/treename/'+id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response) => response.json())
        .then((resdata) => {
            setName(resdata.name)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const fetchParameters = async (id) =>{
        await fetch('https://monicatool.cloud/api/template/all2/'+id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response) => response.json())
        .then((resdata) => {
            resdata.map(d => {
                switch (d.cluster_name){
                    case "Design":
                        let design = d.parameters
                        setDes(old => [...old,...design])
                        break;
                    case "Drawbacks":
                        let drawbacks = d.parameters
                        setDraw(old => [...old,...drawbacks])
                        break;
                    case "Benefits":
                        let benefits = d.parameters
                        setBen(old => [...old,...benefits])
                        break;
                    case "Implementation":
                        let implementation = d.parameters
                        setImpl(old => [...old,...implementation])
                        break;
                    case "Extensions":
                        let extensions = d.parameters;
                        setExt(old => [...old,...extensions])
                        break;

                    default:
                }
            })
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const downloadInstance = async(id) =>{
        await fetch('https://monicatool.cloud/api/download/'+id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((resdata) => {
            window.open('https://monicatool.cloud/instances/'+resdata.data, '_blank')
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const sendFind = async (listsel) =>{
        await fetch('https://monicatool.cloud/api/find', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                search: listsel
            })
        })
        .then((response) => response.json())
        .then((resdata) => {
            if (resdata.data === "No Data"){
                setDiagNoData(true)
            }
            else{
                setInstances(resdata.data)
                setDiagInstances(true)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const clean = async () =>{
        fetch('https://monicatool.cloud/api/addTable', {
          method: 'GET',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': "Bearer "+token
          },
        })
        .then((response) => response.json())
        .then((resdata) => {
            AsyncStorage.setItem('@id_usertable', resdata._id)
            window.location.reload()
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const setDimension = (d) =>{
        if (heightYourInput === 0){
            setHeightYourInput(d.nativeEvent.layout.height)
        }
    }

    const cliboardCopy = (s) =>{
        Clipboard.setString(s)
        setClipBoardMsg('Reference copied. Hint CTRL+V to paste')
        setTimeout(function(){
            setClipBoardMsg('Click on reference to copy to clipbard')
         }, 3000);
    }

    const getDescription = async (id) =>{
        await fetch('https://monicatool.cloud/api/description/'+id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response) => response.json())
        .then((resdata) => {
            setMdData(resdata.html.description)
            setDescription(true)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const getToken = async () => {
        try {
          const value = await AsyncStorage.getItem('@token')
          if(value !== null) {
            return value
          }
          else{
            console.log('error token')
            window.location.reload()
          }
        }
        catch(e) {
          // error reading value
        }
    }

    useEffect(()=>{
        if (des.length > 0)
            setEnd(prev => prev +1)
    },[des])

    useEffect(()=>{
        if (draw.length > 0)
            setEnd(prev => prev +1)
    },[draw])


    useEffect(()=>{
        if (ben.length > 0)
            setEnd(prev => prev +1)
    },[ben])

    useEffect(()=>{
        if (impl.length > 0)
            setEnd(prev => prev +1)
    },[impl])

    useEffect(()=>{
        if (ext.length > 0)
            setEnd(prev => prev +1)
    },[ext])

    useEffect(()=>{
        if (end === 5)
            setisLoading(false)
    },[end])

    useEffect(()=>{
        getToken().then((tkn)=>{
            setToken(tkn)
            getData().then((id)=>{
                setIdUserTable(id)
                fetchParameters(id)
                fetchName(id)
                fetchTree(id)
            })
        })
      }, [firstOpen])

    return (
        <PaperProvider theme={theme}>
   
            <Portal>
                <Dialog visible={description} onDismiss={hideDialogDescription}>
                <Dialog.ScrollArea>
                <ScrollView>
                    <Dialog.Content>
                        <Markdown markdownStyles={{text: {color: 'white'}}}>
                            {mdData}
                        </Markdown>
                    </Dialog.Content>
                </ScrollView>
                </Dialog.ScrollArea>
                </Dialog>
                <Dialog visible={cleanInput} style={{heith:200, width:500, alignSelf:'center'}} onDismiss={hideDialogClean}>
                <Dialog.Title>
                    Are you sure to clean all your input
                </Dialog.Title>
                <Dialog.Actions>
                    <Button style={{paddingRight:30}} onPress={hideDialogClean}>No</Button>
                    <Button onPress={clean}>Yes</Button>
                </Dialog.Actions>
                </Dialog>

                <Dialog visible={loading} style={{heith:200}}>
                    <ActivityIndicator animating={true} color='white' />
                    <Text>Loading...</Text>
                </Dialog>
                <Dialog visible={visible} onDismiss={hideDialog} style={{maxHeight:500}}>

                    <Parametri
                        title={parametro}
                        type={type}
                        options={options}
                        setOptions={setOptions}
                        idPar={idPar}
                        idTab={idUserTable}
                        close={hideDialog}
                        load={setLoading}
                    />
                </Dialog>
                <Dialog visible={diagNoData} onDismiss={hideDialogNoData} style={{height:500}}>
                    <Dialog.Title>
                        <View style={{flexDirection:'row', justifyContent:'space-between',width:'100%'}}>
                            <View style={{flex:8}}>
                                No Monitoring Systems Found
                            </View>
                        </View>
                    </Dialog.Title>
                </Dialog>
                
                <Dialog visible={diagInstances} onDismiss={hideDialogInstance} style={{height:500}}>
                <Dialog.Title>
                <View style={{flexDirection:'row', justifyContent:'space-between',width:'100%'}}>
                    <View style={{flex:6}}>
                        List of suitable Monitoring Systems 
                    </View>
                    <View style={{flex:2, alignItems:'flex-end'}}>
                        <Text style={{fontSize:10}}>{clipboardMsg}</Text>
                    </View>
                </View>
                </Dialog.Title>
                <Dialog.ScrollArea>
                <ScrollView contentContainerStyle={{paddingHorizontal: 24}}>
                    <Dialog.Content>
                    <>
                        {instances.map((o,i)=>{
                            return(
                                <>
                                <View style={{flex: 1,flexDirection: 'row'}}>
                                    <View style={{flex: 7}}>
                                        <List.Item
                                            title={o.name}
                                            titleStyle={{textDecorationLine:'underline'}}
                                            description={o.reference}
                                            onPress={()=>cliboardCopy(o.reference)}
                                        />
                                    </View>
                                    <View style={{flex: 1, justifyContent: 'center'}}>
                                        <Button mode="contained" onPress={()=>downloadInstance(o._id)}>More details</Button>
                                    </View>
                                </View>
                                </>
                            )
                        })}
                    </>
                    </Dialog.Content>
                    </ScrollView>
                </Dialog.ScrollArea>
                </Dialog>
            </Portal>
            <View style={{flex: 1,flexDirection: 'row', justifyContent: 'space-around', backgroundColor: theme.colors.background }} >
              <View style={{flex: 6,flexDirection: 'column', justifyContent: 'space-around', backgroundColor: theme.colors.background}} >
                {isLoading ? (
                    <><Text>Loading</Text></>
                ) : (
                    <>
                    <View style={{flex:8, paddingHorizontal:5, paddingVertical:0 }}>
                        <Card style={{flex:1, backgroundColor:'#292929'}}>
                            <Card.Title title="Provide your Monitoring Requirements" style={styles.title} />
                        </Card>
                    </View>
                    <View style={{flexDirection:'row'}} >
                        <View style={{flex:8, paddingHorizontal:10, paddingTop:0, paddingBottom:4 }}>
                            <Card style={{flex:1, backgroundColor:'#394DBD'}}>
                                <Card.Title title="Design" style={styles.title} />
                                <Card.Content style={{flexDirection:'row'}}>
                                    <View style={{flex:1}}>
                                        <>
                                        {/* scorrimento prima metà array Design */}
                                        {des.slice(0,(des.length/2)).map((d,i)=>{
                                            return(
                                                <List.Item
                                                    titleStyle={styles.text}
                                                    style={styles.boxParameters}
                                                    title={d.name}
                                                    key={i}
                                                    onPress={()=> {getValue(d.id)}}
                                                    right={props => <Icon {...props} size={20} name="more-horiz" onPress={()=>getDescription(d.id)}/>}
                                                />
                                                
                                            )
                                        })}
                                        </>
                                    </View>
                                    <View style={{flex:1}}>
                                        <>
                                        {/* scorrimento seconda metà array Design */}
                                        {des.slice((des.length/2)).map((d,i)=>{
                                            return(
                                                <List.Item
                                                    titleStyle={styles.text}
                                                    style={styles.boxParameters}
                                                    title={d.name}
                                                    key={i}
                                                    onPress={()=> {getValue(d.id)}}
                                                    right={props => <Icon {...props} size={20} name="more-horiz" onPress={()=>getDescription(d.id)}/>}
                                                />
                                            )
                                        })}
                                        </>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    </View>
                    <View style={{flexDirection:'row'}} >
                        <View style={{flex:4, paddingHorizontal:10, paddingVertical:4}}>
                            <Card style={{flex:1, backgroundColor:'#306BAB' }}>
                                <Card.Title title="Drawbacks" style={styles.title} />
                                <Card.Content style={{flexDirection:'row'}}>
                                    <View style={{flex:1}}>
                                        <>
                                        {/* scorrimento prima metà array Drawbacks */}
                                        {draw.slice(0).map((d,i)=>{
                                            return(
                                                <List.Item
                                                    titleStyle={styles.text}
                                                    style={styles.boxParameters}
                                                    title={d.name}
                                                    key={i}
                                                    onPress={()=> {getValue(d.id)}}
                                                    right={props => <Icon {...props} size={20} name="more-horiz" onPress={()=>getDescription(d.id)}/>}
                                                />
                                            )
                                        })}
                                        </>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                        {/* <View style={{flex:1}} /> */}
                        <View style={{flex:4,paddingHorizontal:10, paddingVertical:4}}>
                            <Card style={{flex:1, backgroundColor:'#6F9CEB' }}>
                                <Card.Title title="Benefits" style={styles.title}/>
                                <Card.Content style={{flexDirection:'row'}}>
                                    <View style={{flex:1}}>
                                        <>
                                        {/* scorrimento prima metà array Benefits */}
                                        {ben.slice(0).map((d,i)=>{
                                            return(
                                                <List.Item
                                                    titleStyle={styles.text}
                                                    style={styles.boxParameters}
                                                    title={d.name}
                                                    key={i}
                                                    onPress={()=> {getValue(d.id)}}
                                                    right={props => <Icon {...props} size={20} name="more-horiz" onPress={()=>getDescription(d.id)}/>}
                                                />
                                            )
                                        })}
                                        </>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                        {/* <View style={{flex:0.5}} /> */}
                    </View>
                    <View style={{flexDirection:'row'}} >
                        {/* <View style={{flex:0.5}} /> */}
                        <View style={{flex:4, paddingHorizontal:10, paddingTop:8, paddingBottom:2}}>
                            <Card style={{flex:1, backgroundColor:'#99B9F2' }}>
                                <Card.Title title="Implementation" style={styles.title}/>
                                <Card.Content>
                                    <View style={{flex:1}}>
                                        <>
                                        {/* scorrimento prima metà array Drawbacks */}
                                        {impl.slice(0).map((d,i)=>{
                                            return(
                                                <List.Item
                                                    titleStyle={styles.text}
                                                    style={styles.boxParameters}
                                                    title={d.name}
                                                    key={i}
                                                    onPress={()=> {getValue(d.id)}}
                                                    right={props => <Icon {...props} size={20} name="more-horiz" onPress={()=>getDescription(d.id)}/>}
                                                />
                                            )
                                        })}
                                        </>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                        {/* <View style={{flex:1}} /> */}
                        <View style={{flex:4, paddingHorizontal:10, paddingTop:8, paddingBottom:2}}>
                            <Card style={{ flex: 1, backgroundColor:'#918FF4' }}>
                                <Card.Title title="Extensions" style={styles.title}/>
                                <Card.Content>
                                    <View style={{flex:1}}>
                                        <>
                                        {/* scorrimento prima metà array Drawbacks */}
                                        {ext.slice(0).map((d,i)=>{
                                            return(
                                                <List.Item
                                                    titleStyle={styles.text}
                                                    style={styles.boxParameters}
                                                    title={d.name}
                                                    key={i}
                                                    onPress={()=> {getValue(d.id)}}
                                                    right={props => <Icon {...props} size={20} name="more-horiz" onPress={()=>getDescription(d.id)}/>}
                                                />
                                            )
                                        })}
                                        </>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                        {/* <View style={{flex:0.5}} /> */}
                    </View>
                    </>
                )}
              </View>
              <View
                onLayout={(event)=>setDimension(event)} 
                style={{
                    flex: 2,
                    flexDirection: 'column',
                    justifyContent: 'start', 
                    backgroundColor: theme.colors.background,
                    borderLeftColor: 'white',  
                    borderLeftWidth: 1 
                    }
                    }>
                    <Card style={{flex:1, backgroundColor:'#292929'}}>
                        <Card.Title title="Your input"  subtitle={name} style={styles.title} />
                    </Card>
                    <ScrollView style={{ height: 0.8 * heightYourInput}}>
                    <List.AccordionGroup>
                    {list.map((l,i)=>{
                        return(
                        <List.Accordion title={l.cluster_name} id={i.toString} titleStyle={styles.title}>
                            {l.parameters.map((opt,ind)=>{
                                return(
                                    <List.Item title={opt.name}
                                        description={opt.sel ? (opt.option) :(opt.option+': '+opt.value)}
                                        titleStyle={styles.text}
                                        descriptionStyle={styles.text}
                                        onPress={()=>{getValue(opt.idPar)}}
                                     />
                                )
                            })}
                        </List.Accordion>
                        )
                    })}
                    </List.AccordionGroup>
                    </ScrollView>
                    <View style={{flexDirection:'row'}}>
                        <View style={{flex:2}}></View>
                        <View style={{flex:6, flexDirection:'column', justifyContent:'space-around'}}>
                            <View style={{paddingBottom:10}}>
                            <Button mode="outlined" onPress={() => setCleanInput(true)}  style={{borderColor: 'white'}} disabled={list.length > 0 ? false: true}>
                                clean your input
                            </Button>
                            </View>
                            <View style={{paddingBottom:10}}>
                            <Button mode="outlined" onPress={() => sendFind({list})} style={{borderColor: 'white'}}  disabled={list.length > 0 ? false: true}>
                                find monitoring systems
                            </Button>
                            </View>
                        </View>
                        <View style={{flex:2}}></View>
                    </View>
                </View>
            </View>
        </PaperProvider>
    );
}

export default HomeScreen;

const styles = StyleSheet.create({
    title: {
        fontSize: 16,
      //textTransform: 'uppercase',
    },
    text: {
        fontSize: 14,
        textTransform: 'uppercase',
    },
    boxParameters :{
        padding: 2
    }
  });