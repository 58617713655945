import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions, Image } from 'react-native';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { HelperText, Button, Card, Title, Paragraph, List, Portal, Dialog, DataTable, RadioButton, Checkbox, Drawer, TextInput } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { emailValidator, passwordValidator } from '../utils/index';


const theme = {
    ...DefaultTheme,
    roundness: 2,
    myOwnProperty: true,
    colors: {
      ...DefaultTheme.colors,
      background: '#292929',
      primary: 'white',
      accent: 'white',
      text: '#ffffff',
      surface:'#292929'
    },
  };

function SignInScreen({ navigation, route }) {

    const [email,setEmail] = useState({ value: '', error: '' })
    const [password, setPassword] = useState({ value: '', error: '' });

    const [firstOpen,setFirstOpen] = useState()

    const getData = async () => {
        try {
          const value = await AsyncStorage.getItem('@id_usertable')
          return value
        }
        catch(e) {
          // error reading value
        }
      }

    const onLoginPressed = async () => {
        const emailError = emailValidator(email.value);
        const passwordError = passwordValidator(password.value);
    
        if (emailError || passwordError) {
            var tempEmail = email
            email.error =emailError
            setEmail({...email, tempEmail})
            password.error = passwordError
            setPassword({...password, passwordError})
            return
        }
    
        await fetch('https://monicatool.cloud/api/auth/signin', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email.value,
                password: password.value
            })
        })
        .then((response) => response.json())
        .then((resdata) => {
            if (resdata.error){
                email.error = "User not found"
                setEmail({...email, tempEmail})
            }
            else{
                if(!resdata.accessToken){
                    if(resdata.message === "Email not validate"){
                        email.error = "Email not validate"
                        setEmail({...email, tempEmail})
                    }
                    if(resdata.message === "Password not valid"){
                        password.error = "Password not valid"
                        setPassword({...password, passwordError})
                    }
                }
                else{
                    AsyncStorage.setItem('@token', resdata.accessToken)
                    window.location.reload()
                }
            }
        })
        .catch((error) => {
            console.log(error)
        })
    
        
    };

    useEffect(()=>{

      }, [firstOpen])

    return (
        <PaperProvider theme={theme}>
            <Portal>
                <Dialog visible={true} style={{width:'50%', alignSelf:'center'}}>
                    <Dialog.Title>
                        <View style={{flexDirection:'column', width:'100%', alignItems:'center'}}>
                            <View>
                                <Text style={{fontSize:20}}>MONICA tool</Text>
                            </View>
                            <View>
                                <Text style={{fontSize:14}}>your ally in monitoring system design</Text>
                            </View>
                        </View>
                    </Dialog.Title>
                    <Dialog.Content>
                        <View style={{flexDirection:'column'}}>
                            <View style={styles.rowDialog}>
                                <Text style={styles.textTitle}>Welcome</Text>
                                <Text style={styles.textSubTitle}>Sign in to get started</Text>
                            </View>
                            <View style={styles.rowDialog}>
                                <TextInput
                                    label="Email"
                                    returnKeyType="next"
                                    value={email.value}
                                    onChangeText={text => setEmail({ value: text, error: '' })}
                                    error={!!email.error}
                                    autoCapitalize="none"
                                    autoCompleteType="email"
                                    textContentType="emailAddress"
                                    keyboardType="email-address"
                                    underlineColor="grey"
                                    theme={{ colors: { placeholder: 'grey' } }}
                                />
                                <HelperText type="error" visible={!!email.error}>
                                    {email.error}
                                </HelperText>
                            </View>
                            <View style={styles.rowDialog}>
                                <TextInput
                                    label="Password"
                                    returnKeyType="done"
                                    value={password.value}
                                    onChangeText={text => setPassword({ value: text, error: '' })}
                                    error={!!password.error}
                                    errorText={password.error}
                                    underlineColor="grey"
                                    theme={{ colors: { placeholder: 'grey' } }}
                                    secureTextEntry
                                    onSubmitEditing={onLoginPressed}
                                />
                                <HelperText type="error" visible={!!password.error}>
                                    {password.error}
                                </HelperText>
                            </View>
                            {/* <View style={styles.rowDialog}>
                                <TextInput
                                    label="Confirm Password"
                                    returnKeyType="done"
                                    value={password.value}
                                    onChangeText={text => setPassword({ value: text, error: '' })}
                                    error={!!password.error}
                                    errorText={password.error}
                                    underlineColor="grey"
                                    theme={{ colors: { placeholder: 'grey' } }}
                                    secureTextEntry
                                />
                                <HelperText type="error" visible={!!password.error}>
                                    {password.error}
                                </HelperText>
                            </View> */}
                            <View style={{width:'100%'}}>
                                <TouchableOpacity onPress={() => navigation.navigate('ForgotPassword')}>
                                    <Text style={styles.forgot}>Forgot your password?</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.rowDialog}>
                                <Button mode="contained" 
                                    color="white"
                                    onPress={onLoginPressed}
                                >
                                    Login
                                </Button>
                            </View>
                                <TouchableOpacity onPress={() => navigation.navigate('SignUp')}>
                                    <Text style={styles.signup}>Don’t have an account? Sign up</Text>
                                </TouchableOpacity>
                            </View>
                            <View>
                                <Text style={styles.textECSEL}>
                                    This work has received funding from the Electronic Component Systems 
                                    for European Leadership (ECSEL) Joint Undertaking under grant agreement 
                                    no 783162 (FitOpTiVis) 
                                </Text>
                                {/* <ImageBackground source={{uri:'https://docs.expo.io/static/images/tutorial/splash.png'}} style={{flex: 1, height:'100px'}} /> */}
                            </View>
                            <View style={{alignItems:'center'}}>
                                <Image
                                    style={{width:'100%', height:100, resizeMode:'contain'}} 
                                    source={{uri:'https://qrml.org/img/logoFitoptivis.png'}}
                                />
                            </View>
                    </Dialog.Content>
                </Dialog>
            </Portal>
            <View style={{flex:1, with: useWindowDimensions().width, height:useWindowDimensions().height, backgroundColor: theme.colors.background}}>
            
            </View>
        </PaperProvider>
    );
}

export default SignInScreen

const styles = StyleSheet.create({
    title: {
      fontSize: 16,
      textTransform: 'uppercase',
    },
    textTitle: {
        color:"white",
        fontSize: 20,
        //textTransform: 'uppercase',
    },
    textSubTitle:{
        color:"white",
        fontSize: 14,
    },
    textECSEL:{
        color:"white",
        fontSize: 14,
        paddingTop:10,
        textAlign:'justify'
    },
    boxParameters :{
        padding: 2
    },
    rowDialog:{
        flex:1,
        paddingVertical:20
    },
    forgot:{
        textAlign: 'right',
        color:'grey',
        textDecorationLine: 'underline'
    },
    signup:{
        textAlign: 'center',
        color:'grey',
        textDecorationLine: 'underline'
    },

  });